import { Satellite } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const buttonClickReport = createSlice({
  name: "buttonClickReport",
  initialState: {
    //for urlShortner Reports
    buttonClickReportsData: [],
    totalButtonLinksCount: 0,

    //for Detailed Reports
    shortUrl: "",
    longUrl: "",
    totalLinkClicks: 0,
    dateBasedClicks: {},
    deviceBasedClicks: {},
    browserBasedClicks: {},
  },
  reducers: {
    SetButtonClickReportData: (state, action) => {
      state.buttonClickReportsData = action.payload.data;
      state.totalButtonLinksCount = action.payload.totalCount;
      // state.totalLoadRecords = state.totalLoadRecords + action.payload.userReports.length;
    },

    ResetUrlShortnerReport: (state, action) => {
      state.totalLinkClicks = 0;
      state.dateBasedClicks = {};
      state.deviceBasedClicks = {};
      state.browserBasedClicks = {};
    },

    SetUrlsForDetailedStatistics: (state, action) => {
      state.longUrl = action.payload.long_url;
      state.shortUrl = action.payload.short_url;
    },

    SetDetailedStatisticsData: (state, action) => {
      state.totalLinkClicks = action.payload.totalCounts;
      state.dateBasedClicks = action.payload.dateBasedStatistics;
      state.deviceBasedClicks = action.payload.deviceBasedStatistics;
      state.browserBasedClicks = action.payload.browserBasedStatistics;
    },
  },
});

export const { SetButtonClickReportData, ResetUrlShortnerReport, SetDetailedStatisticsData, SetUrlsForDetailedStatistics } = buttonClickReport.actions;

export default buttonClickReport.reducer;
