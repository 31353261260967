export const NODE_TYPES = {
    PLAIN_MESSAGE_TEXT_NODE: 'plainMessageTextNode',
    QUESTION_NODE: 'questionNode',
    LIST_NODE: 'listNode',
    INPUT: 'input',
    OUTPUT: 'output',
    WEBHOOK_NODE: 'webhookNode',
    ITERATION_GUARD_NODE: 'iterationGuardNode',
    LOOP_BACK_NODE: 'loopBackNode',
    CHANNEL_SWITCH_NODE: 'channelSwitchNode',
    SUB_FLOW_NODE: 'subFlowNode',
    LOCATION_OUTPUT_NODE: 'locationOutputNode',
    LOCATION_INPUT_NODE: 'locationInputNode',
    DYNAMIC_BUTTON_NODE: 'dynamicButtonNode',
    DYNAMIC_LIST_NODE: 'dynamicListNode',
    SEND_SMS_CARD: 'sendSMSCard',
    SEND_EMAIL_CARD: 'sendEmailCard',
    JSON_MAPPER_CARD: 'jsonMapperCard',
    LIVE_AGENT_NODE: 'liveAgentNode',
    GET_RECIPIENT_DATA_NODE: 'getRecipientDataNode',
    SAVE_RECIPIENT_DATA_NODE: 'saveRecipientDataNode',
    VARIABLE_ASSIGNMENT_NODE: 'variableAssignmentNode',
    CONSTANT_ASSIGNMENT_CARD: 'constantAssignmentCard',
    DOCUMENT_COGNITION_CARD: 'documentCognitionCard',
    RESPONSE_CARD: 'responseCard',
    BROADCAST_NODE: 'broadcastNode',
    HTTP_TRIGGER_NODE: 'httpTriggerNode',
    REGISTER_HTTP_TRIGGER_NODE: 'registerHttpTriggerNode',
    RABBITMQ_NODE: 'rabbitMqNode',
    BROADCAST_SMS_NODE: 'broadcastSMSNode',
    SET_LOCALE_NODE: 'setLocaleNode',
    WHATSAPP_FLOWS_NODE: 'whatsAppFlowsNode',
    CONNECTOR_NODE: 'connectorNode',
    WHATSAPP_CATALOG_NODE: 'whatsAppCatalogNode',
    WHATSAPP_CTA_NODE:'whatsAppCTANode',
}