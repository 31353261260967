import { configureStore } from "@reduxjs/toolkit";
import botReducer from "./slices/Bots";
import botsListSlice from "./slices/BotsList";
import botsTemplatesList from "./slices/BotsTemplatesList";
import campaignListSlice from "./slices/CampaignList";
import MessageTemplateList from "./slices/MessageTemplateList";
import orderListSlice from "./slices/orderList";
import orgDataSlice from "./slices/orgData";
import usersListSlice from "./slices/Users";
import workflowEditorStateSlice from "./slices/WorkflowEditor.slice";
import workflowListSlice from "./slices/WorkflowList";
import channelsSlice from "./slices/Channels";
import IntegrationReducer from "./slices/Integrationslice";
import teamListReducer from "./slices/TeamListReducer";
import WorkflowEditorActionSlice from "./slices/WorkflowEditorAction.slice";
import SignUpSlice from "./slices/SignUp";
import SubscriptionSlice from "./slices/subscription";
import dashboardslice from "./slices/Dashboardslice";
import ActivitySlice from "./slices/ActivityFeed";
import ConversationLogsSlice from "./slices/ConversationLogs";
import AdditionalIntegrationSlice from "./slices/AdditionalIntegrationslice";
import ProcessesListSlice from "./slices/ProcessesListSlice";
import ProcessWorkflowListSlice from "./slices/ProcessWorkflowListSlice";
import ProcessBroadcastGatewayReports from "./slices/ProcessGatewayBroadcast";
import BroadcastIntegrationslice from "./slices/BroadcastIntegrationslice";
import BusinessUnitSlice from "./slices/BusinessUnitSlice";
import WhatsAppFlowSlice from "./slices/WhatsAppFlowSlice";
import urlShortnerReport from "./slices/urlShortnerReport";
import RabbitMqIntegrationslice from "./slices/RabbitMqIntegrationslice";
import connectorsSlice from "./slices/ConnectorsSlice";
import buttonClickReport from "./slices/buttonClickReport";

export const store = configureStore({
    reducer: {
        bots_list: botsListSlice,
        workflow_list: workflowListSlice,
        bot: botReducer,
        bots_templates: botsTemplatesList,
        order_list: orderListSlice,
        users: usersListSlice,
        org: orgDataSlice,
        channels: channelsSlice,
        dashboard: dashboardslice,
        workflow_editor_state: workflowEditorStateSlice,
        workflow_editor_action: WorkflowEditorActionSlice,
        campaign_list: campaignListSlice,
        message_template_list: MessageTemplateList,
        integrations: IntegrationReducer,
        additionalIntegrations: AdditionalIntegrationSlice,
        team: teamListReducer,
        signUp: SignUpSlice,
        subscriptions: SubscriptionSlice,
        activityFeed: ActivitySlice,
        conversationLogs: ConversationLogsSlice,
        processes_list: ProcessesListSlice,
        process_workflow_list: ProcessWorkflowListSlice,
        process_broadcast_reports: ProcessBroadcastGatewayReports,
        broadcast_integrations: BroadcastIntegrationslice,
        business_unit_state: BusinessUnitSlice,
        whatsapp_flows: WhatsAppFlowSlice,
        urlShortnerReportsState: urlShortnerReport,
        buttonClickReportsState:buttonClickReport,
        rabbitMq_integrations: RabbitMqIntegrationslice,
        connectors: connectorsSlice
    },
    devTools: true,
});
